<template>
  <div>
    <b-overlay :show="loading">
      <form-wizard
        color="#ae2012"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- accoint details tab -->
        <tab-content title="Pilih Rumpun" :before-change="validationForm">
          <validation-observer ref="pilihRumpun" tag="form">
            <div class="text-center" style="margin-top: 50px">
              <center>
                <validation-provider
                  #default="{ errors }"
                  name="Rumpun"
                  rules="required"
                  class="mt-4"
                >
                  <span class="mb-3"
                    ><strong> Halo {{ user.name }}</strong
                    >, Selamat datang di Tryout {{ tryoutDetail.name }} Silakan
                    pilih rumpun terlebih dahulu sebelum memulai tryout</span
                  >
                  <br />
                  <br />
                  <b-form-group :state="errors.length > 0 ? false : null">
                    <v-select
                      style="width: 50%"
                      id="rumpun"
                      v-model="pilihRumpun"
                      :options="rumpunName"
                      label="text"
                      placeholder="Pilih Rumpun"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </center>
            </div>
          </validation-observer>
        </tab-content>

        <!-- target jurusan tab -->
        <tab-content title="Target Jurusan" :before-change="validationFormInfo">
          <validation-observer
            ref="targetJurusan"
            tag="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <h4 class="mb-0">
              <b> Silahkan pilih univertas dan jurusan yang dituju</b>
            </h4>
            <small class="text-danger">*maksimal 2 pilihan </small>

            <b-row
              class="mt-2"
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="6">
                <b-form-group label="Universitas" label-for="universitas">
                  <validation-provider
                    #default="{ errors }"
                    name="Universitas"
                    rules="required"
                  >
                    <v-select
                      id="universitas"
                      v-model="item.universitas"
                      @input="fetchDataJurusan(index)"
                      :options="universitasName"
                      label="text"
                      placeholder="Pilih Universitas"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Jurusan" label-for="jurusan">
                  <validation-provider
                    #default="{ errors }"
                    name="Jurusan"
                    rules="required"
                  >
                    <v-select
                      id="jurusan"
                      v-model="item.jurusan"
                      :options="item.jurusanName"
                      label="text"
                      placeholder="Pilih Jurusan"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
          <center>
            <b-button
              v-if="this.items.length <= 1"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="repeateAgain"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Tambah</span>
            </b-button>
            <b-button
              v-if="this.items.length > 1"
              class="mt-1 ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              @click="removeItem"
            >
              <feather-icon icon="TrashIcon" class="mr-25" />
              <span>Hapus</span>
            </b-button>
          </center>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      pilihRumpun: "",
      pilihUniversitas: [],
      universitas_id: 0,
      pilihJurusan: [],
      nextTodoId: 1,
      universitas: null,
      items: [
        {
          id: 1,
          universitas: null,
          jurusan: null,
          jurusanName: [],
        },
      ],
      selectedContry: "",
      selectedLanguage: "",
      name: "",
      emailValue: "",
      PasswordValue: "",
      passwordCon: "",
      first_name: "",
      last_name: "",
      address: "",
      landMark: "",
      pincode: "",
      twitterUrl: "",
      facebookUrl: "",
      googleUrl: "",
      linkedinUrl: "",
      city: "",
      required,
      email,
      rumpunName: [],
      universitasName: [],
      jurusanName: [],
      loading: false,
    };
  },
  watch: {},
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
  },
  methods: {
    async fetchDataJurusan(index) {
      if (this.items[index].universitas != null) {
        this.items[index].jurusanName = await this.getDataJurusan(
          this.items[index].universitas.id
        );
      }
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
        universitas: null,
        jurusan: null,
        jurusanName: [],
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem() {
      this.items.splice(this.items.length - 1, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    async formSubmitted() {
      let payloadRumpun = {
        paket_id: this.tryoutDetail?.id,
        rumpun_id: this.pilihRumpun?.id,
        jadwal_id: this.$route.query.j,
      };
      let payloadJurusan = this.items.map((item) => {
        return {
          paket_id: this.tryoutDetail?.id,
          school_id: item?.universitas?.id,
          major_id: item?.jurusan?.id,
          jadwal_id: this.$route.query.j,
          user_id: this.user?.id,
        };
      });

      try {
        this.loading = true;
        // save rumpun siswa
        await this.$store.dispatch("ujian/saveRumpunSiswa", payloadRumpun);
        await this.$store.dispatch("ujian/saveJurusanSiswa", payloadJurusan);
        this.loading = false;
        this.$router.push(`/tryout-detail-subtest?j=${this.$route.query.j}`);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.pilihRumpun.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.targetJurusan.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getDataRumpun() {
      this.$store.dispatch("rumpun/index", {}).then((response) => {
        let rumpun = response.data.data;
        rumpun.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.rumpunName = rumpun;
      });
    },
    getDataUniversitas() {
      const params = {
        is_official_school: 0,
      };
      this.$store.dispatch("masterUniv/index", params).then((response) => {
        let universitas = response.data.data;
        universitas.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.universitasName = universitas;
      });
    },
    async getDataJurusan(universitas_id) {
      let params = {
        school_id: universitas_id,
      };

      if (this.pilihRumpun) {
        params.rumpun_id = this.pilihRumpun?.id;
      }
      let jurusans = await this.$store.dispatch("jurusan/index", params);
      let jurusan = jurusans.data.data;
      jurusan.map((item) => {
        item.value = item.id;
        item.text = item.name;
      });
      return jurusan;
    },
  },
  created() {
    if (this.tryoutDetail.name == undefined || !this.$route.query.j) {
      this.$router.go(-1);
    }
    this.getDataRumpun();
    this.getDataUniversitas();
  },
};
</script>
